.radiobtnWrapper {
    position: relative;
}
.radiobtnWrapper.questionWithScores .MuiFormGroup-root,
.radiobtnWrapper .MuiFormGroup-root {
	min-height: 77px;
	display: flex;
	justify-content: center;
}

.radiobtnWrapper.questionWithScores .MuiFormGroup-root {
	width: 315px;
}

.optionSelected .radiobtnWrapper .MuiFormGroup-root {
    width: 315px;
}
.undo_btn {
	position: absolute;
	top: 17px;
    left: 75px;
	opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
}
.optionSelected .undo_btn {
    opacity: 1;
    visibility: visible;
}
.optionsBtn * {
    transition: all 0.4s linear;
}
.optionSelected .optionsBtn * {
    display: none;
}
.optionSelected .optionsBtn .checked + .optionText,
.optionSelected .optionsBtn .checked {
    display: block;
}