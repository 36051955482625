.what_kaleido,
.thinnking_intro,
.thinking_styles {
    padding: 70px 0;
}
.what_kaleido p.text-dark {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.6;
}
.thinnking_intro p,
.what_kaleido p {
    margin: 0 0 20px;
    text-align: justify;
}
.image-v4-item {
    position: relative;
}
.image-v4-item::after {
	height: 90%;
	width: 90%;
	position: absolute;
	left: 55px;
	border-radius: 67% 52% 78% 51% / 60% 61% 76% 57%;
	bottom: 25px;
	border: 3px solid var(--white);
	content: '';
	display: block;
    -webkit-animation: sliderShape 5s linear infinite;
    animation: sliderShape 5s linear infinite;
}
.tagline {
	position: absolute;
	left: 0;
	top: -20px;
	padding: 20px;
	background: var(--primary-color);
	color: var(--white);
	font-size: 1.3rem;
	line-height: 1.6;
	font-weight: 500;
	width: 300px;
	border: 6px solid;
	border-radius: 27px;
	text-align: center;
	z-index: 2;
}

.thinnking_intro {
    background: url(../../../assets/Images/Mask-group-1.jpg) no-repeat bottom left / cover;
    color: var(--white-color);
    padding-bottom: 130px;
}

.thinnking_intro .about-title,
.thinnking_intro p {
    color: var(--white-color);

}
.card--wrapper {
    padding: 48px 40px 32px;
    background: var(--white-color);
    margin-top: 30px;
    border-radius: 15px;
    box-shadow: 0px 9px 30px 0px #EDF0F2;
    text-align: center;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    border-top: 4px solid transparent;
    height: calc(100% - 30px);
}
.card--wrapper:hover {
    border-color: var(--primary-color);
}
.card--wrapper .card--image {
    height: 80px;
    width: 80px;
    background: #EDF4FE;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    border-radius: 100px;
    color: var(--primary-color);
    margin-bottom: 21px;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}
.card--image img {
    width: 45px;
}
.card--wrapper:hover .card--image {
    background-color: var(--primary-color);
}
.card--wrapper:hover .card--image img {
    filter: brightness(0) invert(1);
    width: 45px;
}
.card--text h2{
    font-size: 1.25em;
    color: var(--secondary);
}

.card--text p{
    line-height: 1.4;
    text-align: justify;
    color: var(--text-color);
}

@media (max-width: 1200px) {
    .image-v4-item {
        position: relative;
        max-width: 450px;
        margin: 0 auto;
    }
    .image-v4-item::after {
        left: 10px;
    }
    .thinnking_intro {
        background-size: auto;
        background-color: #474ff8;
    }
}