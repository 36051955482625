.dashboard_heading {
	background: var(--lightest-gray) url(../../assets/Images/page-title.jpg) no-repeat center;
	position: relative;
	text-align: center;
	padding: 140px 0 40px;
	color: var(--black);
}
.dashboardNavPane,
.dashboar-container .tabWrapper {
	border-bottom: 1px solid var(--lightest-gray);
	box-shadow: 1px 0 4px var(--light-gray);
	position: relative;
}

.dashboar-container .nav {
	border: 0 none;
}

.dashboar-container .nav .nav-link {
	padding: 10px 26px;
	color: var(--gray);
	font-weight: 400;
}

.dashboar-container .nav .nav-link.active {
	color: var(--primary-color);
	border-bottom-color: var(--primary-color);
}

.dashboar-container .tab-content .tab-pane > .container,
.dashboar-container .dashboardTopbar + .container {
	padding-top: 50px;
	padding-bottom: 50px;
	min-height: calc(100vh - 352px);
}

.dashboar-container .tab-content .tab-pane > .dashboardTopbar + .container {
	min-height: calc(100vh - 404px);
}

.dashboardTopbar {
	padding: 10px 0;
	background: var(--body-background);
	height: 60px;
	display: flex;
	align-items: center;
}

.dashboardTopbar .form-control {
	min-height: 40px;
}

.btn.btn-circle {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	color: white;
	font-weight: 900;
	padding: 0;
	line-height: 60px;
	text-transform: uppercase;
	font-size: 0.8rem;
	pointer-events: none;
}

.card.border-card {
	border-bottom: 5px solid var(--primary-color);
}

.dashboar-container .tab-content .card-title {
	color: var(--black);
}

.dashboar-container .tab-content .card-subtitle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dashboar-container .tab-content .card-subtitle svg {
	width: 16px;
	margin-right: 4px;
}

.trainers-badge {
	position: absolute;
	background: var(--primary);
	line-height: 1;
	padding: 4px 5px;
	border-radius: 5px;
	color: var(--white);
	right: 4px;
	top: 4px;
	font-size: 0.75em;
}

.friendListCard {
	height: 220px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: var(--lightest-gray);
	border-radius: 10px;
	padding: 15px;
}

.friendList-item {
	padding: 10px;
}

.friendList-item:not(:last-child) {
	border-bottom: 1px solid var(--card-border);
}

.friendList-item p {
	line-height: 1.2;
	overflow: hidden;
	text-overflow: ellipsis;
}

.friendList-item .btn-close {
	vertical-align: middle;
	width: 13px;
	height: 11px;
	box-sizing: border-box;
}

.comparisonWrap {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px;
	background-color: rgba(3, 12, 49, 0.89);
	z-index: 1000;
}
@media (max-width: 1200px) {
	.dashboardTopbar {
		height: auto;
	}
	.dashboar-container .tab-content .tab-pane > .dashboardTopbar + .container {
		min-height: calc(100vh - 376px);
	}
}
@media (max-width: 992px) {
	.dashboar-container .nav .nav-link {
		padding: 10px 1px;
		font-size: 13px;
	}
	.friendListCard {
		height: auto;
		margin-bottom: 30px;
		max-height: 300px;
		overflow-y: scroll;
	}	
}
@media (max-width: 400px) {
	.dashboar-container .nav .nav-link {
		padding: 3px 0px;
		font-size: 12px;
	}
	.dashboardTopbar .form-control {
		min-height: 35px;
	}
}