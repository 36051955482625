.main-header--wrapper{
	position: absolute;
    left: 0;
    right: 0;
	z-index: 1001;
}

.extraNav {
	padding: 5px 0;
	border-bottom: 1px solid var(--border-color);
}
.navbar-light .navbar-nav .nav-link {
	color: var(--black);
	font-size: 15px;
	font-weight: 500;
}

.logo img{
	width: 95px;
	display: block;
}

.navbar-expand-lg {
	justify-content: space-between !important;
}

.nav-item a{
	font-weight: 600;
}

.dropbtn {
	border: none;
	cursor: pointer;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown a > img {
	width: 16px;
	top: 0px;
	vertical-align: baseline;
}
.dropdown-content {
	background-color: var(--white);
	min-width: 200px;
	width: max-content;
	padding: 0;
	border-color: transparent;
	transition: all 0.4s ease;
	opacity: 0;
	visibility: hidden;
	top: 150%;
	display: block;
}
.dropdown:hover .dropdown-content {
	opacity: 1;
	visibility: visible;
	top: 100%;
}
.nav-item.dropdown:last-child > div.dropdown-content{
	right: 0;
	left: auto;
}

.dropdown-content button{
	padding: 10px 29px;
}

.dropdown-content button > img {
	width: 16px;
	margin-right: .5em;
}

.dropdown-content a {
	color: var(--black);
	padding: 16px;
	text-decoration: none;
	display: block;
	cursor: pointer;
	font-size: 1rem;
	line-height: 2;
}
.dropdown-content a:hover {
	background-color: transparent;
	color: var(--primary-color);
}
.dropdown-content a:not(:last-child) {
	border-bottom: 1px solid var(--border-color);
}
.nav-link {
	display: inline-block;
	padding: 0.2rem 0.5rem;
}

.nav-link, .nav-link:focus, .nav-link:hover {
	color: var(--primary-color);
}

.nav-item:hover .nav-link {
	opacity: .85;	
}

.navbar-nav .nav-link {
	padding-right: 0.4rem;
	padding-left: 0.4rem;
	margin: 0px 4px;
	border: 2px solid transparent;
	text-align: center;
}
.navbar-nav .nav-item--app.nav-link {	
	min-width: 100px;
}
.social-link{
    width: 17px;
    height: 17px;
    display: inline-block;
    text-align: center;
	transition: all 0.3s ease;
	margin: 0 5px;
}
.social-link img {
	width: 100%;
	display: block;

}
@media (min-width: 992px) and (max-width: 1200px) {
	.navbar-light .navbar-nav .nav-link {
		min-width: 50px;
		font-size: 15px;
	}
}
/* @media (min-width: 992px) */
@media (min-width: 1200px) {
	.nav-item--app .nav-link {
		border-radius: 20px !important;
		background-color: var(--primary-color);
		color: var(--lightest-gray) !important;
	}
	.nav-item--not-filled .nav-link{
		background-color: transparent;
		border: 2px solid var(--primary-color);
		color: var(--primary-color) !important;
	}
	
	.nav-item--filled.nav-link{
		background-color: var(--primary-color);
		border: 2px solid var(--primary-color);
		border-radius: 20px !important;
		background-color: var(--primary-color);
		color: var(--lightest-gray) !important;
	}
}
@media (max-width: 1200px) {
	.extraNav {
		font-size: 12px;
	}
	#collapsibleNavbar .navbar-nav .dropdown-menu {
		border: 0 none;
	}
	#collapsibleNavbar {
		background: var(--lightest-gray);
		text-align: left;
		border-radius: 10px;
		border: 1px solid var(--light-gray);
	}
	.navbar-light .navbar-nav .nav-link {
		width: 100%;
		text-align: left;
		border-bottom: 1px solid var(--light-gray);
		padding: 10px;
		margin: 0;
	}
	.navbar-light .navbar-nav .dropdown-content {
		width: 100%;
		height: 0;
	}
	.navbar-light .navbar-nav .dropdown:hover .dropdown-content {
		height: auto;
	}
}