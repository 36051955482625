
.bg-img1 + .footer {
    /* background-color: var(--white); */
    text-align: center;
    /* padding: 10px;
    color: var(--label-color); */
}
.heading,
.logoLogin,
.logo_login p {
    text-align: center
}

input::-ms-clear,
input::-ms-reveal {
    display: none
}

* {
    box-sizing: border-box
}

img {
    width: 100%
}

.showHidePass{
    z-index: 1000;
    position: absolute;
    top:48px;
    right: 10px;                                                                                                                                                                                                                                            
}
.login .showHidePass{
    /* top:61px; */
}
.showHideOuterDiv {
    position: relative;
}

.showHideOuterDiv input {
    padding-right: 2.4em;
}

.login_wrapper {
	min-height: calc(100vh - 100px);
	background: rgb(249,244,236);
	background: linear-gradient(45deg, rgba(233,237,247,1) 0%, rgba(249,244,236,1) 100%);
	padding: 30px 50px 10px;
	margin-left: 6rem;
	border-radius: 20px;
	width: 520px;
	max-width: 60%;
}
.loginWrap_title {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 10px;
}
.login {
	margin: -190px auto 90px;
	width: 530px;
	max-width: 100%;
	padding: 5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: var(--body-background);
	border-radius: 20px;
	position: relative;
	z-index: 12;
}

.login input.form-control {
	/* padding: 20px; */
	border-color: var(--card-border);
	background-color: var(--white);
	/* border-radius: 13px; */
}
.login input.form-control:hover,
.login input.form-control:focus,
.login input.form-control:active {
	border-color: var(--border-color);
    background-color: var(--white);
	box-shadow: 0 0 9px var(--light-gray);
}

.logo_login {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	align-items: start;
}
.loginBox .checkbox {
	display: flex;
	width: 100%;
}
.logo_login p span {
    margin-bottom: 10px;
    margin-top: 10px
}

.checkbox-with-label {
    width: 100%;
    margin-bottom: 10px
}

.registration-form .btn.btn-primary:not(.input-group-text),
.login button.btn.btn-primary,
.login a.btn.btn-primary {
	background: var(--primary-color);
	font-size: 14px;
    border: 2px solid;
	border-color: var(--primary-color);
	border-radius: 0.45rem !important;
	margin: 20px 0 0;
	height: auto;
	font-weight: bold;
	letter-spacing: 1px;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.login a.btn.btn-secondary {
	background: var(--white-color);
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.custom-checkbox .checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid var(--label-color)
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 0 none;
	background: var(--light-gray);
	border-radius: 3px;
	width: 15px;
	height: 15px;
    margin-top: 5px;
    margin-right: 5px
}

.checkbox .checkbox-area .cr {
    margin-right: 0
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both
}

.checkbox .cr .cr-icon {
	position: absolute;
	font-size: 10px;
	line-height: 1;
	top: 2px;
	left: 2px;
	color: var(--text-color);
}

.checkbox .cr .cr-icon .fa-check::before {
	color: var(--text-color);
}

.registration-form {
	max-width: 850px;
    width: 90%;
    margin: -190px auto 90px;
	padding: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: var(--body-background);
	border-radius: 20px;
	position: relative;
	z-index: 12;
}
.bg-img1.background label,
.registration-form label {
	color: var(--darker-gray);
	font-size: 14px;
	margin-bottom: 6px;
	font-weight: 600;
}

.bg-img1.background {
    color: var(--darker-gray)
}

.loginBox {
    margin-top: 4px
}

.login .heading {
	font-size: 25px;
	margin: 20px 0 0;
	font-weight: 300;
}

.meritech_logo {
    width: 100%;
    max-width: 260px
}

.logoLogin {
    width: 100%;
    margin: 0 auto
}
.login .form-group {
	margin-bottom: 0.9rem;
}
.remember {
    margin-left: 4px;
    width: auto;
    margin-top: 2px;
}

label.withlabel-checkbox1 {
    width: auto;
    margin-top: 5px
}

@media (orientation:landscape) {
    .background::before {
        background-color: var(--darker-gray);
        background-size: auto 100%
    }
}

@media (max-width:1240px) {
    .loginBox {
        margin-top: 12px
    }
}

@media (max-width: 992px) {
    .bg-img1.background {
        padding: 50px 15px;
        min-height: calc(100vh - 347px);
    }
    .registration-form,
    .login {
        position: initial;        
        padding: 20px;
        margin: 0 auto;
    }

    .logo_login {
        position: inherit;
        display: flex;
        flex-direction: column;
        margin-top: 11px;
        align-items: end
    }

    .background::before {
        z-index: -1;
        background-position: center
    }
}
@media (max-width: 565px) {
    .login {
        width: 100%;
    }
}