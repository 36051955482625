:root {
    --transparent-black: rgba(191, 207, 255, 0.1);
    --transparent-white: hsla(0, 0%, 100%, 0.1);
    --font-size: 15px;
    --bs-border-color: #cfdff8;
    --bs-border-radius: 0.3rem;
    --white-color: #fff;
    --dark-primary: #0f1b35;
    --primary-color: #0E59F2;
    --primary: #0b2048;
    --light-primary: #dde8fa;
    --lightest-primary: #f5f9ff;
    --red: #cf2e2e;
    --green: #00d084;
    --blue: #0693e3;
    --yellow: #fcb900;
    --lightest_Yellow: #fdeaa7;
    --light_Yellow: #fbda66;
    --Yellow: #f9ca24;
    --dark_Yellow: #deb420;
    --darker_Yellow: #b9961b;

    --lightest_Pink: #fed5e0;
    --light_Pink: #fdb5c9;
    --Pink: #fc95b2;
    --dark_Pink: #e0859e;
    --darker_Pink: #bb6e84;

    --lightest_Red: #f7bfb8;
    --light_Red: #f28f83;
    --Red: #ec5e4e;
    --dark_Red: #d25445;
    --darker_Red: #af463a;

    --lightest_Orange: #f9d5aa;
    --light_Orange: #f5b66a;
    --Orange: #f0962a;
    --dark_Orange: #d68625;
    --darker_Orange: #b26f1f;

    --lightest_Blue: #b4acef;
    --light_Blue: #7c6fe3;
    --Blue: #4330d7;
    --dark_Blue: #3c2bbf;
    --darker_Blue: #32249f;
    --bs-accordion-inner-border-radius: 7px;

    --gray-0: #F8F9FA;
    --gray-100: #f3f4f6;
    --gray-200: #e5e6e8;
    --gray-300: #DBE0E5;
    --gray-400: #BEC8D0;
    --gray-500: #868990;
    --gray-600: #5B6B79;
    --gray-700: #3E4853;
    --gray-800: #1D2630;
    --gray-900: #241f26;
}

.accordion-item {
    color: var(--text-color) !important;
}

@font-face {
    font-family: "Base 4";
    src: url("../fonts/Base 4.eot");
    src: url("../fonts/Base 4.eot?#iefix") format("embedded-opentype"), url("../fonts/Base 4.woff") format("woff"), url("../fonts/Base 4.svg#Base 4") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-007A;
}

html {
    height: 100%;
    width: 100%;
    font-size: var(--font-size);
}

body {
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    color: var(--text-color);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    line-height: 1.8em;
}

* {
    outline: 0px;
}

.wrapper-main {
    background-color: var(--lightest-gray)
}

#moveContent>div:first-child {
    min-height: calc(100vh - 69px);
}

.container-main {
    max-width: 1152px;
    margin: auto;
    padding: 10px 25px;
}

.section {
    text-align: center;
    padding: 5em 2em;
}

.section--blue-bg,
.secondary-text {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
}

.banner--light {
    background: var(--lightest-gray) url(../../assets/Images/page-title.jpg) no-repeat center;
    position: relative;
    min-height: 500px;
    text-align: center;
    padding: 190px 0 240px;
    color: var(--black);
}

.banner--light::before {
    mask-image: url(../../assets/Images/bg-shape-before.svg);
    -webkit-mask-image: url(../../assets/Images/bg-shape-before.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: top;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    mask-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 115px 0;
    background: var(--primary-color);
    z-index: 11;
}

.banner--light::after {
    mask-image: url(../../assets/Images/bg-shape.svg);
    -webkit-mask-image: url(../../assets/Images/bg-shape.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: top;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    mask-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 80px 0;
    background: var(--white-color);
}

.page-title {
    margin: 0;
    padding: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    color: var(--black-color);
    font-size: 60px;
    line-height: 75px;
    font-weight: 500;
    margin-bottom: 18px;
}

.btn.btn-blue.primary-btn.btn-primary {
    background-color: var(--white-color) !important;
    color: var(--primary-color) !important;
}

.primary-btn {
    font-size: 1.2em !important;
    padding: 0.8em 1.8em !important;
    border-radius: 50px !important;
}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 0px;
    outline-offset: 0;
    border: 0px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.form-control::file-selector-button {
    padding: .85rem .75rem;
}
input[type=number] {
    -moz-appearance: textfield;
}

p {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: var(--label-color);
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

h3,
h4,
h5 {
    margin-top: 5px;
    font-weight: 600;
}

ul li {
    list-style: none;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

::selection {
    background: var(--primary);
    color: var(--white);
}

::-moz-selection {
    background: var(--primary);
    color: var(--white);
}

p {
    color: var(--text-color);
}

.text-dark {
    color: var(--dark-text) !important;
}

.text-muted {
    color: var(--gray) !important;
}

.card {
    border: 1px solid var(--card-border);
    border-radius: var(--bs-accordion-inner-border-radius);
    box-shadow: 1px 1px 2px var(--shadow-color);
    background-color: var(--white);
    color: var(--text-color);
    /* overflow: hidden; */
}

.form-group {
    margin-bottom: 15px;
    position: relative;
}

.form-control {
    min-height: 47px;
    border-radius: 8px;
    padding: 5px 10px;
    background: var(--white);
    border: 1px solid #dedcea;
    font-size: 14px;
    color: var(--text-color);
}

.form-control~input {
    padding-right: 2.3em;
}

.form-group .MuiStack-root {
    background: white;
    border-radius: 8px;
    height: 47px !important;
}

.MuiInputBase-input {
    padding: 14px !important;
    color: var(--text-color);
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #dedcea !important;
    border-radius: 8px;
    height: 52px;
}

select.form-control:not([size]):not([multiple]) {
    height: 38px;
    background: var(--white) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.form-control:focus,
#focusedInput {
    box-shadow: none;
    color: var(--text-color);
    background-color: var(--white);
}

.form-control:disabled,
.form-control[readonly] {
    background-color: var(--light-gray);
    opacity: 1;
    cursor: not-allowed;
}

.custom-file .custom-file-label::after {
    color: var(--gray);
    content: "" !important;
    background: var(--lightest-gray) url(../Images/category_icon_vector_data-analytics.svg) no-repeat center / 20px auto;
    border-left: 1px solid var(--light-gray);
    border-radius: 0 .25rem .25rem 0;
    min-width: 30px;
}

.custom-file .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-group-text {
    background-color: var(--card-border);
    border: 1px solid var(--input-border);
}

.input-group+.input-group:not(:last-child)>.input-group-prepend>.label-style.input-group-text,
.input-group+.input-group:not(:last-child)>.custom-select:not(:first-child),
.input-group+.input-group:not(:last-child)>.form-control:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1);
    margin-bottom: calc(var(--bs-border-width) * -1);
}

.input-group:not(:last-child)>.input-group-prepend>.label-style.input-group-text {
    border-bottom-left-radius: 0;
}

.input-group:last-child>.input-group-prepend>.label-style.input-group-text {
    border-bottom-left-radius: 4px;
}

.input-group:first-child>.input-group-prepend>.label-style.input-group-text {
    border-top-left-radius: 4px;
}

.input-group:first-child>.input-group-prepend+.custom-select:not(:first-child),
.input-group:first-child>.input-group-prepend+.form-control:not(:first-child) {
    border-top-right-radius: 4px;
}

.input-group:last-child>.input-group-prepend+.custom-select:not(:first-child),
.input-group:last-child>.input-group-prepend+.form-control:not(:first-child) {
    border-bottom-left-radius: 4px;
}

.input-group-text {
    color: var(--light-label);
}

label {
    font-weight: normal;
    color: var(--label-color);
    margin-bottom: .1em;
}

.p-0 {
    padding: 0px;
}

::-ms-clear {
    display: none;
}

input[disabled] {
    cursor: default;
    background: var(--input-border);
    color: var(--label-color);
    border-color: var(--input-border);
}

ul {
    margin: 0px;
    padding: 0px;
}

a[disabled] {
    pointer-events: none;
    color: #95979a;
}

.form-control:focus,
#focusedInput {
    border: 1px solid var(--primary-color) !important;
    box-shadow: none;
}

.multiple-email.form-control:focus{
    border: none !important;
}

.react-multi-email {
    padding-top: .5em !important;
    border-radius: 8px !important;
}

.react-multi-email > input[type='text'] {
    padding: 0.4em 0.1em !important;
    z-index: 1 !important;
    background: transparent !important;
}

.react-multi-email [data-tag] {
    font-size: 14px !important;
    line-height: 1.1 !important;
    margin: 0.25em 0.2em !important;
}

.react-multi-email.empty > span[data-placeholder] {
    font-weight: normal !important;
    left: 0.3em !important;
    top: 0.55em !important;
}

/*textarea*/
textarea {
    overflow: auto;
    width: 100%;
    border: 1px solid var(--input-border);
    padding: 5px;
    /* resize: none;
    */
}

.form-group.textarea {
    width: 100%;
    margin: 10px 0;
}

.textarea {
    width: 100%;
}

/*select*/
select option:hover {
    box-shadow: 0 0 10px 100px var(--primary) inset;
    background: var(--primary);
    color: var(--white);
}

option[selected]:hover {
    background: var(--primary);
    color: var(--white);
}

select[disabled] {
    cursor: default;
    background: var(--input-border);
    color: var(--label-color);
    border-color: var(--input-border);
    border: 1px solid var(--input-border);
}

/*button*/
.btn:not(.btn-sm) {
    text-align: center;
    padding: 10px 14px;
    font-size: 14px;
    line-height: 1;
}

.btn-sm {
    font-size: 0.75rem;
}

.btn>svg {
    width: 14px;
    height: 14px;
    fill: currentColor;
    vertical-align: middle;
}

.btn.btn-md {
    padding: 8px 12px;
}

.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--light-primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white);
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--shadow-color);
}

.btn.btn-primary:focus {
    background: var(--primary);
    color: var(--white);
    border-color: var(--primary);
    box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: none;
}

.btn-secondary {
    color: var(--white);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-light {
    color: var(--text-color) !important;
    background-color: var(--white);
    border: 1px solid var(--light-gray);
}

.btn-light:hover {
    color: var(--black) !important;
    background-color: var(--lightest-gray);
    border: 1px solid var(--light-gray);
}

.btn-danger {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
}

.btn-outline-danger {
    border-color: var(--lightest_Red);
}

.btn-outline-secondary {
    border-color: var(--light-gray);
}

.btn-outline-warning {
    border-color: var(--lightest_Yellow);
}

.btn-outline-info {
    border-color: #b4f1fa;
}

.btn-outline-success {
    border-color: #81ebb3;
}

button.btn-danger.btn.btn-primary:focus {
    background: var(--red);
    color: var(--white);
    border-color: var(--red);
    box-shadow: none;
}

.tab-action button+button {
    margin-left: 13px !important;
    margin-top: 0px !important;
}

button.close:focus {
    color: var(--text-color);
    outline: none;
}

button:focus {
    outline: 0px !important;
}

span.closebtn {
    float: right;
    font-size: 20px;
    cursor: pointer;
}

.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--primary);
}

button {
    background: transparent;
    border: none;
    cursor: pointer;
}

.filter-left-area li:first-child a {
    padding-left: 0px;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: var(--primary);
}

.pagination>li>a {
    cursor: pointer !important;
    color: var(--black);
}

.pagination>.active>a:not([href]):not([tabindex]):focus,
.pagination>.active>a:not([href]):not([tabindex]):hover {
    color: var(--white);
}

.btn-sm+.dropdown-menu .dropdown-item {
    font-size: 0.75rem;
}

/*search*/
.page-input-search::-webkit-outer-spin-button,
.page-input-search::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.page-input-search[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.searchPlaceholder::-webkit-input-placeholder {
    font-size: 9px;
}

/*custome scrollbar*/
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-input-placeholder {
    color: var(--black);
    font-size: 13px;
}

/*placeholder*/
.form-control::-moz-placeholder {
    color: var(--text-color);
}

.form-control:-ms-input-placeholder {
    color: var(--text-color);
}

.form-control:-moz-placeholder {
    color: var(--text-color);
}

/*table*/
.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
    background-color: #ebf2f9;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    vertical-align: middle;
}

.table thead th {
    border-bottom: 1px solid var(--card-border);
}

.tableDesign thead {
    background: var(--secondary);
    position: sticky;
    top: 0;
    z-index: 4;
}

.table-bordered> :not(caption)>* {
    border-color: var(--border-color);
}

.tableDesign tbody tr td:first-child {
    border-left: 0px;
}

.tableDesign tbody tr td:last-child {
    border-right: 0px;
}

.tableDesign th {
    color: var(--white-color) !important;
    background: var(--secondary);
}

.tableDesign table.table.table-bordered {
    border-bottom: 1px solid var(--input-border);
    border-left: 0px;
    border-right: 0px;
    position: relative;
    border-top: 0px;
}

.tableDesign .table thead th {
    border: 0px;
    font-weight: normal;
}
.table td.last-column, .table th.last-column {
	text-align: right;
}
.row-eq-height {
    height: calc(100vh - 62px);
    background: var(--white);
    box-shadow: 0 4px 24px 0 var(--shadow-color);
    border: 1px solid var(--card-border);
    overflow: auto;
}

.row-eq-height .table {
    height: auto !important;
}

.table {
    height: 100%;
    margin-bottom: 0;
}

.table-responsive {
    height: 100%;
}

tr.YellowAlert {
    background: var(--primary);
}

.table td,
.table th {
    text-align: left;
    padding: 7px;
    white-space: nowrap;
    color: var(--text-color);
}

tbody {
    display: table-row-group;
    vertical-align: middle;
}

/*backgrounds*/
.bg-lightgray {
    background-color: var(--lightest-gray) !important;
}

.accordion-item {
    color: var(--text-color) !important;
    background-color: var(--white) !important;
    border: 1px solid var(--border-color) !important;
}

.dropdown-menu {
    background-color: var(--white);
    color: var(--text-color);
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--text-color);
    background-color: var(--light-gray);
}

/*wrapper body content*/
.wrapper {
    display: block;
    width: 100%;
}

.wrapper .ShiftLeftSide {
    padding: 0px 0 0px 139px;
}

.wrapper .ShiftLeftSide .icon-nav {
    padding-left: 194px;
}

.content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.address--wrapper {
    padding-top: 5em;
    padding-bottom: 5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*nav bar top of page */
nav {
    position: relative;
    width: auto;
}

.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    top: 0px;
}

.navbar {
    min-height: 30px;
}

.add-list {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
}

.add-list li {
    cursor: pointer;
}

.add-list li a {
    cursor: pointer;
    text-align: left;
    font-size: 14px;
}

.add-list .fa,
.add-list .fas {
    color: var(--white);
}

.filter-left-area h1 {
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: normal;
}

.filter-left-area h1 img {
    width: 18px;
    margin-right: 4px;
}

.filter-left-area {
    width: auto;
    display: flex;
}

.filter-left-area a {
    font-size: 18px;
    color: var(--black);
    cursor: pointer;
    line-height: 24px;
    padding: 0 6px;
}

.add-list li a:hover {
    color: var(--white-color);
}

.icon-nav {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    background: var(--white);
    z-index: 102;
    position: fixed;
    padding: 10px;
    box-shadow: 0 4px 24px 0 var(--shadow-color);
    border-bottom: 1px solid var(--card-border);
    padding-left: 52px;
}

.icon-nav .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.filter-left-area .mob-search li a {
    color: var(--label-color);
    font-size: 21px;
    line-height: 26px;
}

.filter-left-area .mob-search li.add-mob a {
    margin-right: 0px;
}

.icon-nav.m-b-0 {
    margin-bottom: 0;
}

.page-count-no select {
    padding: 0px 0px;
    border: 1px solid var(--input-border);
    height: 25px;
    width: auto;
    position: relative;
    margin: 0px;
}

.page-count-no select option {
    position: absolute;
    top: -5px;
}

.page-count-no select:hover {
    background: var(--white);
}

.page-number input[type="text"] {
    width: 80px;
}

.page-count-no {
    margin: 0 3px;
    padding: 0px 0 0;
}

.page-number {
    line-height: 10px;
}

.page-number ul li a,
.page-number ul li .page-span {
    height: 25px;
    margin: 0;
    width: auto;
    border-left: 0px;
    text-align: center;
    padding: 2px 8px 3px;
    line-height: 20px;
    border: 1px solid var(--input-border);
    border-left: 0px;
}

.page-count-no {
    padding: 0 0px;
    margin-right: 0px;
    padding-right: 0px;
}

/*custome checkbox*/
span.cr:focus {
    border: 1px solid var(--primary) !important;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 9px;
    line-height: 0;
    top: 52%;
    left: 13%;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon,
.radio label input[type="radio"]+.cr>.cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.radio label input[type="radio"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: 0.5;
}

/*modal pop up width correction*/
.range-modal .modal-dialog.modal-dialog-centered {
    width: 635px;
}

.radio label,
.checkbox label {
    min-height: auto;
    padding-left: 0px;
    width: auto;
    margin: 0px;
    vertical-align: middle;
}

.checkbox {
    margin: 0px;
}

.form-check.form-switch.ml-4 {
    margin-top: -3px;
}

/*custome radio button*/
input[type="radio"]+label::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    height: 14px;
    width: 14px;
    background: var(--white);
    border: 1px solid var(--gray);
    border-radius: 8px;
    left: 0px;
}

input[type="radio"]+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 8px;
    left: 3px;
}

input[type="radio"]+label {
    position: relative;
    padding: 0 0 0 18px !important;
    line-height: 25px;
    margin: 0 6px 0 0;
    z-index: 1;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    /* width: auto; */
}

input[type="radio"]:checked+label::before {
    background: var(--primary);
    border-color: var(--primary);
}

input[type="radio"]:disabled+label {
    opacity: 0.5;
}

.radio {
    margin-bottom: 0px;
}

/*modal pop up width Time Correction*/
.modal-dialog:not('.modal-lg'),
.modal-dialog:not('.modal-xl') {
    max-width: 700px;
}

.large-modal .modal-dialog {
    max-width: 1000px;
}

.modal {
    height: 100%;
}

.modal-body {
    padding: 15px 20px 15px;
    background: var(--white);
    width: 100%;
}

.modal-body.noBackground {
    background: transparent;
}

.modal-header.modal-title {
    width: 100%;
}

.modal-title {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin: 0;
}

.modal-content {
    border: var(--light-secondary);
    background-color: var(--white);
}

.modal-header {
    padding: 20px 25px 35px;
    border: 0 none;
    background: var(--lightest-gray) url(../../assets/Images/page-title.jpg) no-repeat center;
    color: var(--black);
    border-top: 2px solid var(--primary-color);
    position: relative;
}

.modal-header::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 0;
    background: url(../../assets/Images/bg-shape-before.svg) repeat center / auto 100%;
    z-index: 11;
}

.modal-header::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    background: url(../../assets/Images/bg-shape.svg) repeat center / auto 100%;
}

.modal-header button.close {
    opacity: 1;
    box-shadow: none;
    font-weight: normal;
    margin: 0;
    color: var(--light-gray);
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    line-height: 1;
    height: auto;
    text-shadow: var(--shadow-color);
}

.modal-header .Crossicons {
    position: absolute;
    right: 6px;
    top: 9px;
}

.modal-header svg {
    fill: var(--gray);
    width: 40px;
    height: 40px;
}

.modal-footer {
    text-align: right;
    background: var(--lightest-gray);
    padding: 10px 20px;
    border-top: 0 none;
}

.modal-footer .form-group {
    margin-bottom: 0rem;
}

.delete-popup.modal-dialog {
    width: 400px;
}

.delete_popup .modal-header {
    text-align: center;
    justify-content: center;
    background: var(--white);
}

.modal-dialog .loaderContainer {
    width: 100%;
    transform: scale(4.5);
    transform-origin: center;
}

/*modal pop up width correction*/
.correction-modal .modal-dialog.modal-dialog-centered {
    width: 400px;
}

/*modal pop up width user*/
.add-user-modal .modal-dialog {
    width: 430px !important;
}

/*modal pop up width device*/
.add-device-modal .modal-dialog {
    width: 750px !important;
}

/*modal pop up width change password*/
.changePasswordModal .modal-dialog,
.modal-dialog.otp-popup {
    width: 500px !important;
}

/*modal pop up width change logout*/
.modal-dialog.no-data-found-popup {
    width: 300px;
}

/*modal pop up width Stop Alert-Confirmation*/
.modal-dialog.stop-popup {
    width: 400px;
}

/*modal pop up for delete Confirmation*/
.modal-dialog.delete_popup {
    max-width: 420px;
    text-align: center;
}

.modal-dialog.delete_popup .modal-footer {
    background-color: var(--white);
    justify-content: center;
}

.delete_popup .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.confirm_popup .modal-header {
    text-align: center;
    justify-content: center;
    background: var(--white);
}

.modal-dialog.confirm_popup {
    max-width: 420px;
    text-align: center;
}

.modal-dialog.confirm_popup .modal-footer {
    background-color: var(--white);
    justify-content: center;
}

.modal-dialog.delete_popup .modal-title::after {
    content: '!';
    display: block;
    width: 80px;
    height: 80px;
    background: transparent;
    border-radius: 50%;
    margin: 30px auto 0;
    line-height: 80px;
    color: var(--red);
    font-size: 40px;
    border: 2px solid #f43d3d;
    transform: scale(0);
    transition: all 0.3s ease 0.2s;
}

.show .modal-dialog.delete_popup .modal-title::after {
    transform: scale(1);
}

.add-profile-modal .modal-dialog {
    width: 400px;
}

.errorMessage {
	font-size: 12px;
	width: 100%;
	text-align: left;
	line-height: 18px;
	display: block;
}

.customPages svg {
    fill: var(--black);
}

.bootbox-body {
    word-break: break-word;
}

.add-deviceData-modal .modal-footer {
    border-top: 0px solid var(--input-border);
}

/*loader css*/
@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    /* vertical-align: text-bottom;
    */
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    margin-left: 5px;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.getFlex {
    display: flex;
}

.relayError .tooltiptext {
    visibility: hidden;
    width: 198px;
    background-color: var(--white);
    color: var(--red);
    border: 1px solid var(--red);
    text-align: center;
    border-radius: 6px;
    padding: 4px 0px;
    position: absolute;
    z-index: 1;
    font-size: 9px;
    left: 0;
    top: 28px;
    margin: 0px;
}

.getFlex:hover .tooltiptext {
    visibility: visible;
}

.no_data {
    width: 60px;
    height: 55px;
}

.message {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.messageLine {
    padding-top: 5px;
}

/* responsive view start*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .device-detail .table {
        overflow-x: auto;
    }
}

.groupSelect {
    padding: 2px;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    /* max-width: 80px;
    */
    text-overflow: ellipsis;
}

.timeCorrection {
    padding-left: 8px;
    padding-top: 1px;
}

.errMessageRange {
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    line-height: 1;
}

.errMessageRange:last-child {
    left: calc(50% + 10px);
}

img.gridChartIcons {
    height: 16px;
    width: 16px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    -webkit-transform: translate(14px, 4px) scale(1);
    -moz-transform: translate(14px, 4px) scale(1);
    -ms-transform: translate(14px, 4px) scale(1);
    transform: translate(14px, 4px) scale(1);
}

.react-bootstrap-table table {
    table-layout: inherit !important;
}

.tableHeaderFixed thead {
    background: var(--secondary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.tableHeaderFixed thead tr {
    width: 100%;
    display: table;
}

.tableHeaderFixed {
    padding-top: 25px;
    position: relative;
}

.tableDesign.row-eq-height.tableHistory {
    height: auto;
}

.otpStyle {
    justify-content: space-between;
}

.otpInputStyle {
    width: 2.2rem !important;
    height: 2.2rem;
    margin: 0 .2rem;
    font-size: 1.5rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .3);
}

.paymentCardSummary {
	background-color: var(--lightest-gray);
	border-radius: 10px;
	padding: 25px;
}

#tooltip-top > .tooltip-inner {
    background-color: #ffffff !important;
    border: 1px solid #ebebeb !important;
    text-align: left !important;
    opacity: 1 !important;
    color: #000;
    font-weight: 600;
    /* border: 1px solid #062e56; */
    box-shadow: 0 .125rem 1.25rem rgba(0, 0, 0, 0.075) !important;
}

#tooltip-top > .tooltip-arrow {
    background: #fff;
}

#tooltip-top > .tooltip-arrow::before {
    background: #fff;
}


@media (min-width:1400px) {
    .row-eq-height {
        height: auto;
        overflow: auto;
        max-height: calc(100vh - 48px);
    }
}

@media (max-width:1200px) {
    .wrapper .ShiftLeftSide .icon-nav {
        padding-left: 0;
    }

    .wrapper .ShiftLeftSide {
        padding: 0;
    }

    .wrapper .ShiftLeftSide .chartFilter {
        padding-left: 0;
    }
}

@media (max-width: 930px) {
    .icon-nav {
        height: auto;
    }

    .add-list {
        margin: 10px 0 0;
        flex-direction: column;
        width: 100%;
    }

    .row-eq-height {
        margin-top: 37px;
        height: calc(100vh - 102px);
    }
}

@media (max-width: 992px) {
    .wrapper .container-fluid {
        padding: 0 10px !important;
    }

    .modal-dialog {
        width: 98%;
    }
    .changePasswordModal .modal-dialog, .modal-dialog.otp-popup {
        width: 550px !important;
        max-width: 70%;
    }

    .icon-nav {
        height: auto;
    }

    .row-eq-height {
        height: 100%;
    }

    .icon-nav {
        padding-left: 0;
    }

    .alertVTop .groupSelect {
        width: auto;
    }

    .filter-left-area {
        padding-left: 57px;
    }

    .add-list {
        padding-left: 57px;
    }

    .theme-btns, input[type="submit"],
    .primary-btn {
        font-size: 1em !important;
        padding: 0.7em 1.5em !important;
    }
    .page-title {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 0;
    }
    .banner--light {
        padding: 150px 0 100px;
        min-height: 120px;
    }
    .banner--light::after {
        padding: 40px 0;
    }
    .banner--light::before {
        padding: 55px 0;
    }
}

@media (max-width:767px) {
    .wrapper {
        padding-left: 0;
    }

    .row-eq-height {
        height: calc(100vh - 84px);
    }    
    .banner--light::after {
        padding: 20px 0;
    }
    .banner--light::before {
        padding: 35px 0;
    }
}

@media (max-width:700px) {
    .add-profile-modal .modal-dialog {
        width: 93%;
    }

    .row-eq-height {
        margin-top: 43px;
    }

    .filter-left-area {
        float: right;
        margin: 0 0 10px;
    }

    .add-list {
        margin: 0 0 8px;
        float: left !important;
    }

    .filter-left-area {
        width: 100%;
    }
}

@media (max-width:576px) {
    .icon-nav {
        padding: 5px 0px 2px;
    }

    .add-user-modal .modal-dialog {
        width: 100% !important;
    }

    .changePasswordModal .modal-dialog,
    .modal-dialog.otp-popup {
        width: calc(100% - 2*var(--bs-modal-margin)) !important;
    }

    .range-modal .modal-dialog.modal-dialog-centered {
        width: 100% !important;
    }

    .add-deviceData-modal .nav-link {
        padding: 0.5rem;
    }
    
    .modal-dialog.modal-dialog-centered {
        width: 90% !important;
        margin: 0 auto !important;
    }
}
@media (max-width: 400px) {
	body {
        font-size: 13px;
    }
}
@media (max-width:380px) {
    .tableDesign.row-eq-height.tableHistory {
        margin-top: 71px;
    }

    .relayError .tooltiptext {
        width: 100%;
    }
}

@media (max-width:300px) {
    .currentPage {
        padding-left: 0px !important;
    }
}

@media (min-width: 768px) {
    .container-large {
        max-width: 1152px;
    }
}