.pageInput {
    width: 84px;
    border: 0 none;
    color: var(--gray-800);
    background-color: var(--white);
    height: 25px;
}
.pagination {
    gap: 5px;
}
.page-item .page-link {
	border-radius: 8px !important;
    border: 1px solid var(--gray-300);
    line-height: 1;
    background-color: var(--white);
}
.disabled > .page-link, .page-link.disabled {
	cursor: not-allowed;
    pointer-events: unset;
    background-color: var(--gray-300);
}
.totalpages {
    margin: 0 10px 0 10px;
    font-weight: bold;
}

.currentPage {
    padding-left: 10px;
    border-right: 1px solid var(--gray-300);
}

.customPages {
    border: 1px solid var(--gray-300);
    border-radius: 8px;
}

