
.customList li {
    font-size: 17px;
    line-height: 38px;
    list-style: none;
    position: relative;
    padding-left: 40px;
}
.customList li::after {
	content: "\f058";
	font-family: "Font Awesome 5 Free";
	font-size: 20px;
	color: var(--primary-color);
	font-weight: 400;
	position: absolute;
	height: 22px;
	width: 22px;
	text-align: center;
	line-height: 21px;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
