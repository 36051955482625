.optionsBtn {
	position: relative;
}
.optionsBtn .fa.fa-check {
	position: absolute;
	top: -5px;
	left: auto;
	right: -2px;
	color: white;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	line-height: 20px;
	background: var(--green);
}
.modal-form-SendInvities {
    margin: 0 !important;
    max-width: 100% !important;
}
.ReactTags__tagInputField {
	min-height: 47px;
	border-radius: 8px;
	padding: 5px 10px;
	background: var(--white);
	border: 1px solid #dedcea;
	font-size: 14px;
	display: block;
	width: 100%;
	color: var(--text-color);
}


.ReactTags__tags {
	position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
	width: 100%;
	border-radius: 2px;
	display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	min-height: 38px;
	border-radius: 4px;
	padding: 5px 10px;
	background: var(--white);
	border: 1px solid var(--input-border);
	font-size: 14px;
	display: block;
	width: 100%;
	line-height: 1.5;
	color: var(--text-color);
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
	color:var(--text-color);
	display: block;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
	border: 1px solid var(--light-gray);
	background: var(--lightest-gray);
	color: var(--gray-color);
	font-size: 14px;
	display: inline-block;
	padding: 8px;
	padding-top: 3px;
    padding-bottom: 3px;
	margin-top:8px;
	margin-right: 5px;
	border-radius: 30px;
}

.ReactTags__selected .ReactTags__remove {
	margin-left: 5px;
	cursor: pointer;
	background-color: var(--gray);
	border-radius: 50%;
	width: 25px;
	height: 25px;
	padding: 1px 1px 0 4px;
	text-align: center;
	vertical-align: -2px;
}
.ReactTags__selected .ReactTags__remove svg {
	vertical-align: 2px;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}

.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em var(--shadow-color);
	background: white;
	width: 200px;
}

.ReactTags__suggestions li {
	border-bottom: 1px solid var(--input-border);
	padding: 5px 10px;
	margin: 0;
}

.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: var(--lightest-gray);
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: var(--white);
}