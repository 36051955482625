.filter-wrap {
	border: 1px solid var(--light-gray);
	padding: 1px 9px;
	border-radius: 20px;
	color: var(--dark-text);
	background: var(--lightest-primary);
	height: 35px;
	font-size: 1rem;
	min-width: 100px;
}
.overflow-scroll.white-space-nowrap {
	white-space: nowrap;
}
.filter-wrap.active {
	background-color: var(--darker_Blue);
	color: var(--white);
	border-color: var(--darker_Blue);
}
/* .filter-wrap span:first-child { */
.filter-wrap span.filterType {
	font-size: 0.95em;
	color: var(--text-color);
}
.edit-btn {
    display: block;
}
.edit-btn svg {
    width: 16px;
    height: 16px;
    fill: var(--text-color);
}
.text-dark.titleColumn {
	width: 250px;
	max-width: 290px;
	min-width: 290px;
	white-space: break-spaces;
}
.subtitleList {
	width: 200px;
	min-width: 200px;
	max-width: 200px;
}
.subList {
	width: 100px;
	max-width: 100px;
	min-width: 65px;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media (max-width: 992px) {
	.filter-wrap {
		min-width: 20px;
		margin: 4px;
	}
	.stats-table td {
		display: block;
		width: 100%;
		border: 0 none;
	}
	.stats-table td table tr{
		border-bottom: 1px solid var(--light-gray);
	}
	.stats-table td.subList {
		width: auto;
		display: inline-block;
	}
}
@media (max-width: 400px) {
	.filter-wrap span.filterType {
		max-width: 90px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}