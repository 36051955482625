.languageTextarea textarea.form-control {
    min-height: 90px;
    height: max-content;
}

.timeq-persons {
	margin-right: -20px;
	position: relative;
	z-index: 1;
}
.agent-badge {
	position: absolute;
	background: var(--primary);
	line-height: 1;
	padding: 4px 5px;
	border-radius: 5px;
	color: var(--white);
	right: 4px;
	top: 4px;
	font-size: 0.75em;
}
.input-group.search {
	width: auto;
}
.search .form-control {
	border-radius: 30px 0 0 30px;
	background: var(--lightest-gray);
	border-color: var(--light-gray);
	min-height: 40px;
	width: 230px;
    max-width: 250px;
	border-right: 0 none;
}
.search .input-group-text {
    border-radius: 0 30px 30px 0;
	background: var(--lightest-gray);
	border-color: var(--light-gray);
	min-height: 40px;
	border-left: 0 none;
}
.multi_filter_wrap {
	width: 100%;
	max-height: 150px;
	overflow: scroll;
}
.multi_filter_wrap .form-check {	
	display: flex;
	align-items: center;
}
.multi_filter_wrap .form-check-input {
	margin-top: 0 !important;
}
.multi_filter_wrap .form-check-label {
	line-height: 1;
	margin-left: 10px;
	width: calc(100% - 30px);
	text-overflow: ellipsis;
	overflow: hidden;
}
.public-DraftStyleDefault-block {
	margin: 0;
}
.rdw-editor-wrapper {
	border: 1px solid var(--border-color);
	margin-bottom: 30px;
}
.tabWrapper .accordion-button.collapsed {
	background: var(--light-primary);
	font-size: 1.2rem;
}
.edit_profile_wrapper {
	position: relative;
	border: 1px solid var(--border-color);
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 10px;
}
.edit_profile_btn {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5) url('../../assets/Images/edit_profile.svg') no-repeat center / 50px auto;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s ease;
	color: transparent;
}
.edit_profile_wrapper .edit_profile_btn::file-selector-button {
	display: none;
}
.edit_profile_wrapper:hover .edit_profile_btn {
	opacity: 1;
	visibility: visible;
}
@media (min-width: 992px) {
	.card_name {
		margin-top: 12px;
	}
}
@media (max-width: 992px) {
    .search .form-control {
        width: 180px;
    }
}
@media (max-width: 410px) {
    .search .form-control {
        width: 120px;
        min-height: 30px;
    }
    .search .input-group-text {
        min-height: 30px;
    }
}