.creator {
	text-align: justify;
	position: relative;
    margin-bottom: 70px;
}
.creator .sticky_img {
	text-align: center;
	position: relative;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--card-border);
	border-radius: 30px;
}
.creator img{
    width: 100%;
}
.team-details-title {
	font-size: 30px;
	font-weight: 500;
	line-height: 50px;
	margin-bottom: 6px;
}
.creator .btn {
    height: 50px;
    width: 50px;
    font-size: 18px;
    box-shadow: 0px 10px 35px rgba(35, 31, 80, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 20px;
    color: var(--primary-color);
    background-color: var(--white);
    border-color: var(--white);
}
.creator p {
    margin-bottom: 25px;
}
@media (max-width: 992px) {
    .creator {
        margin-bottom: 20px;
    }
    .creator .sticky_img {
        margin: 20px auto;
        max-width: 360px;
    }
}
