.footer{
    text-align: center;
    background-color: var(--dark-primary);
}

.copyright {
	padding: 20px 0;
	background: var(--dark-primary);
}
@media (max-width: 1200px) {
    .copyright {
        padding: 12px 0;
    }
}