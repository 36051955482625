:root {
	--tile-length: 95px
}

.banner {
	background: var(--lightest-gray) url(../../assets/Images/hero-banner.svg) no-repeat bottom right / cover;
	height: 720px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding-top: 170px;
}

.banner::before {
	background-color: #FFFFFF;
	opacity: 0.7;
	transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

.banner .container {
	z-index: 2;
}

.banner-image img {
	animation: sliderImage 55s linear infinite;
	-webkit-animation: sliderImage 55s linear infinite;
}

.banner-image {
	position: relative;
}

.banner-image::before {
	position: absolute;
	content: "";
	left: 6%;
	bottom: 0%;
	width: calc(6 * .866 * var(--tile-length));
	height: calc(6 * .866 * var(--tile-length));
	border: 1px solid var(--primary-color);
	border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
	will-change: border-radius, transform, opacity;
	animation: sliderShape 5s linear infinite;
	display: block;
	z-index: -1;
	-webkit-animation: sliderShape 5s linear infinite;
}

.banner-image::after {
	position: absolute;
	content: "";
	left: 7%;
	bottom: 1%;
	width: calc(6 * .866 * var(--tile-length));
	height: calc(5.5 * .866 * var(--tile-length));
	border: 1px solid #9e06b8;
	border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
	will-change: border-radius, transform, opacity;
	animation: sliderShape2 5s linear infinite;
	display: block;
	z-index: -1;
	-webkit-animation: sliderShape2 5s linear infinite;
}

@keyframes sliderImage {

	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes sliderShape {

	0%,
	100% {
		border-radius: 50%;
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	44% {
		border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
		transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
	}

	60% {
		border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	87% {
		border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
		transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
	}
}

@keyframes sliderShape2 {

	0%,
	100% {
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	34% {
		border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
		transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
	}

	50% {
		border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
		transform: translate3d(0, 0, 0) rotateZ(0.01deg);
	}

	67% {
		border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
		transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
	}
}

.banner-tagline {
	color: var(--primary-color);
	font-size: 3em;
	font-weight: normal;
}

.banner .settings-content-box {
	display: flex;
	flex-direction: column;
}

.banner .slide-title {
	font-weight: 600;
	margin-bottom: 10px;
	padding: 0px 20px 0px 0px;
	font-size: 62px;
	line-height: 78px;
	font-style: italic;
	position: relative;
}

.banner .slide-title::before {
	content: "\f10d";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: 13px;
	font-size: 149px;
	left: 10px;
	z-index: -1;
	color: var(--primary-color);
	opacity: 0.1;
}

.banner .slide-title span {
	font-weight: 400;
	color: var(--primary-color);
}

.slide-dec {
	margin-bottom: 45px;
	font-family: var(--text-color);
}

.settings-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.theme-btns,
input[type="submit"] {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	padding: 18px 40px;
	display: inline-flex;
	align-items: center;
	position: relative;
	transition: all .35s;
	border: none;
	background: var(--primary-color);
	color: var(--white-color);
	transition: .4s;
	border-radius: 30px;
}

.play-btn:hover,
.theme-btns:hover,
input[type="submit"]:hover {
	color: var(--white);
	background-color: var(--primary);
}

.play-btn {
	width: 55px;
	height: 55px;
	background: var(--primary-color);
	border-radius: 50%;
	position: relative;
	margin: auto;
	display: inline-flex;
	font-size: 20px;
	justify-content: center;
	align-items: center;
	color: var(--white-color);
	margin-right: 15px;
}

.play-btn::before {
	content: "";
	position: absolute;
	width: 150%;
	height: 150%;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation: pulsate1 2s;
	animation: pulsate1 2s;
	-webkit-animation-direction: forwards;
	animation-direction: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: steps;
	animation-timing-function: steps;
	border-radius: 50%;
	border: 1px solid var(--primary-color);
}

@-webkit-keyframes pulsate1 {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px var(--primary-color);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@keyframes pulsate1 {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px var(--primary-color);
	}

	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

.hero-video-btn span {
	color: var(--black-color);
	font-weight: 500;
}

.hero-video-btn span {
	color: var(--black);
	font-weight: 500;
}
.carousel-caption {
	position: static;
	text-align: left;
}
.service-section {
	margin-bottom: 0px;
	padding: 300px 0px 70px 0px;
	background-image: url("../../assets/Images/curve-shape.svg");
	background-position: top center;
	background-repeat: no-repeat;
}

.section-title-content {
	max-width: 450px;
	margin: 0 auto;
	margin-bottom: 66px;
}

.service-section-wrapper {
	position: relative;
	z-index: 2;
}

.service-section-wrapper .row {
	position: relative;
	min-height: 450px;
	height: auto;
}

.service-section-wrapper.hide_service .service-box {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}
.service-box.one.active {
	padding: 0 60px 0;
}
.service-section-wrapper.hide_service .service-box.active {
	position: absolute;
	opacity: 1;
	visibility: visible;
	left: 0;
	right: 0;
	top: 0;
}
.service-section-wrapper .service-box:not(.active) .hide_before,
.service-section-wrapper .service-box:not(.active) .services-two-des {
	display: none;
	text-align: left;
}
.service-section-wrapper .service-box.active .hide_after,
.service-section-wrapper .service-box.active .service-one-icon,
.service-section-wrapper .service-box.active .services-one-des {
	display: none;
}
.service-box.one.active .service-one-title,
.services-two-des.two {
	text-align: left;
}
.services-two-des.two li {
	list-style: disc;
}
.services-two-des.two li::marker {
	color: var(--primary-color);
}
.service-section-wrapper .service-box.active .service-btns {
	transform: rotate(180deg);
	color: var(--black);
}

.section-small-title {
	display: inline-block;
	font-size: 14px;
	line-height: 17px;
	font-weight: 500;
	color: var(--black);
	position: relative;
	padding-left: 23px;
	padding-right: 23px;
	margin-bottom: 18px;
	text-transform: uppercase;
}

.section-small-title::before {
	content: "";
	height: 8px;
	width: 8px;
	background: var(--primary-color);
	top: 0;
	position: absolute;
	bottom: 0;
	margin: auto;
	right: 0;
	border-radius: 2px;
	box-shadow: 0px 3px 11px rgba(14, 89, 242, 0.4);
}

.section-small-title::after {
	content: "";
	height: 8px;
	width: 8px;
	background: var(--primary-color);
	top: 0;
	position: absolute;
	bottom: 0;
	margin: auto;
	left: 0;
	border-radius: 2px;
	box-shadow: 0px 3px 11px rgba(14, 89, 242, 0.4);
}

.section-title {
	margin-bottom: 23px;
	font-weight: 500;
	font-size: 48px;
	line-height: 58px;
}

.section-description {
	font-family: var(--text-color);
}

.service-box {
	padding: 0px 23px 0;
	transition: all 0.3s ease;
	margin-bottom: 30px;
	border: 1px solid var(--border-color);
	border-radius: 15px;
	background: var(--white);
	display: block;
	color: var(--text-color);
	width: 100%;
	height: 100%;
}

.service-one-icon {
	font-size: 45px;
	margin-bottom: 40px;
	height: 75px;
	width: 75px;
	border-radius: 10px;
	color: var(--primary-color);
	-webkit-transition: .4s;
	-moz-transition: .4s;
	-ms-transition: .4s;
	-o-transition: .4s;
	transition: .4s;
	display: inline-flex;
	background: rgba(14, 89, 242, 0.1);
	justify-content: center;
	align-items: center;
}

.service-one-icon svg {
	height: 45px;
	width: 45px;
}

.service-one-title {
	margin-bottom: 15px;
	font-weight: 500;
	font-size: 22px;
	color: var(--secondary);
}

.services-one-des {
	margin-bottom: 10px;
	line-height: 24px;
	font-family: var(--text-color);
}

.service-btns {
	font-size: 35px;
	transform: rotate(320deg);
	margin-top: 5px;
	transition: all .4s ease;
	display: inline-block;
	color: var(--primary-color);
}

.service-box:hover .service-btns {
	transform: rotate(0deg);
	color: var(--black);
}

.service-btns img {
	max-width: 22px;
}

.service-box:hover .service-btns img {
	filter: brightness(0);
}

.slide-subtitle,
.about-small-stitle {
	display: inline-block;
	font-size: 14px;
	line-height: 17px;
	font-weight: 700;
	letter-spacing: 0.5px;
	color: var(--dark-text);
	position: relative;
	padding-left: 23px;
	margin-bottom: 19px;
	text-transform: uppercase;
}

.slide-subtitle::before,
.about-small-stitle::before {
	content: "";
	height: 8px;
	width: 8px;
	background: var(--primary-color);
	top: 0;
	position: absolute;
	bottom: 0;
	margin: auto;
	left: 0;
	border-radius: 2px;
}

.slide-subtitle::before {
	height: 2px;
}

.welcome-section {
	margin-bottom: 0px;
	padding: 93px 0px 95px 0px;
	position: relative;
}

.welcome-section::before {
	background-color: transparent;
	background-image: radial-gradient(at center right, #4268F0 17%, #4268F000 76%);
	opacity: 0.14;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}
.welcome-section .accordion-item {
	border: 0 none !important;
}
.welcome-section .accordion-button {
	padding: 20px 15px;
	background-color: transparent !important;
	border-bottom: 1px solid var(--light-gray);
	box-shadow: none;
	outline: none;
}
.about-title {
	font-weight: 500;
	margin-bottom: 32px;
	font-size: 48px;
	line-height: 58px;
}

.about-des ul {
	margin: 20px 0 40px;
	padding-bottom: 5px;
}

.about-des ul li {
	font-size: 18px;
	line-height: 34px;
	color: var(--secondary);
	list-style: none;
	position: relative;
	padding-left: 15px;
}

.about-des ul li::after {
	content: "";
	height: 6px;
	width: 6px;
	position: absolute;
	background: var(--primary-color);
	top: 0;
	bottom: 0;
	left: 0;
	margin: 15px 0 0;
}

.testcard--wrapper {
	padding: 0px 40px 0px;
	background: var(--white-color);
	margin-top: 50px;
	border-radius: 15px;
	box-shadow: 0px 9px 30px 0px #EDF0F2;
	text-align: center;
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	border-bottom: 4px solid var(--primary-color);
	height: calc(100% - 30px);
}

.testcard--wrapper .testcard--image {
	height: 80px;
	width: 80px;
	background: var(--primary-color);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 45px;
	border-radius: 100px;
	margin-top: -40px;
	margin-bottom: 21px;
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
}

.testcard--image img {
	width: 45px;
	filter: brightness(0) invert(1);
}

.testcard--text h2 {
	font-size: 1.25em;
	color: var(--secondary);
}

.testcard--text p {
	line-height: 1.4;
	text-align: justify;
	color: var(--text-color);
}

.contactus-section {
	padding: 110px 15px 90px 15px;
	background: url(../../assets/Images/1image-1.jpg) no-repeat center;
}

.contact-subtitle {
	display: inline-block;
	font-size: 14px;
	line-height: 17px;
	font-weight: 600;
	color: var(--white-color);
	position: relative;
	margin-bottom: 18px;
	text-transform: uppercase;
}

.contact-title {
	margin-bottom: 23px;
	color: var(--white-color);
	font-weight: 500;
	font-size: 48px;
	line-height: 58px;
}


.kaleidoscope {
	position: relative;
	width: calc(6 * .866 * var(--tile-length));
	height: calc(6 * .866 * var(--tile-length));
	-webkit-animation: rotation 8s linear infinite;
	animation: rotation 8s linear infinite;
	border-radius: 50%;
	margin: 0 auto;
}

.kaleidoscope::after {
	content: '';
	position: absolute;
	height: calc(100% + 10px);
	width: calc(100% + 10px);
	background: radial-gradient(circle at center, transparent 35%, #fff 65%);
	z-index: 10;
	border: 5px solid white;
	border-radius: 50%;
	top: -5px;
	left: -5px;
}

.containerKaleido {
	position: absolute;
	width: calc(2 * var(--tile-length));
	height: calc(2 * var(--tile-length));
	border-radius: 50%;
	overflow: hidden;
	transform: translate(calc(var(--tile-length) * -0.134), -50%);
}

.containerKaleido:nth-child(1) {
	top: 50%;
	left: 0%;
}

.containerKaleido:nth-child(2) {
	top: 50%;
	left: calc(2 * calc(var(--tile-length) * 0.866));
}

.containerKaleido:nth-child(3) {
	top: 50%;
	left: calc(4 * calc(var(--tile-length) * 0.866));
}

.containerKaleido:nth-child(4) {
	top: calc(50% - calc(1.5 * var(--tile-length)));
	left: calc(var(--tile-length) * 0.866);
}

.containerKaleido:nth-child(5) {
	top: calc(50% - calc(1.5 * var(--tile-length)));
	left: calc(3 * var(--tile-length) * 0.866);
}

.containerKaleido:nth-child(6) {
	top: calc(50% + calc(1.5 * var(--tile-length)));
	left: calc(var(--tile-length) * 0.866);
}

.containerKaleido:nth-child(7) {
	top: calc(50% + calc(1.5 * var(--tile-length)));
	left: calc(3 * var(--tile-length) * 0.866);
}

@-webkit-keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.tile {
	position: absolute;
	top: 28%;
	left: 25%;
	width: calc(var(--tile-length) * 0.996);
	height: calc(var(--tile-length) * 0.866);
	-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tile:nth-child(1) {
	transform: rotate(30deg) translateY(50%) scaleX(-1);
}

.tile:nth-child(2) {
	transform: rotate(90deg) translateY(50%);
}

.tile:nth-child(3) {
	transform: rotate(150deg) translateY(50%) scaleX(-1);
}

.tile:nth-child(4) {
	transform: rotate(210deg) translateY(50%);
}

.tile:nth-child(5) {
	transform: rotate(270deg) translateY(50%) scaleX(-1);
}

.tile:nth-child(6) {
	transform: rotate(330deg) translateY(50%);
}

.texture {
	position: absolute;
	height: calc(1.75 * var(--tile-length));
	width: calc(1.75 * var(--tile-length));
	background-image: url(../../assets/Images/6p27.png);
	background-size: 100%;
	-webkit-animation: rotation 8s linear infinite;
	animation: rotation 8s linear infinite;
}

/***********************************************
    /***** Customar Service Section Style Css **** 
    ************************************************/

.customar-service-section-wraper {
	background-image: url(../../assets/Images/bg-2.jpg );
	border-radius: 20px;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

.customar-service-content {
	align-items: center;
}

.customar-support-area {
	padding: 25px 60px;
	background: var(--primary-color);
	position: absolute;
	right: 0;
	bottom: 0;
	color: var(--white-color);
	border-bottom-right-radius: 20px;
}

.customar-service-content .about_content {
	padding: 60px 0 60px;
}

.customar-service-image {
	margin-top: 30px;
}

span.customar-title-center-border {
	height: 1px;
	width: 140px;
	position: relative;
	background: var(--white-color);
	display: inline-block;
	margin: 0 20px;
	top: -3px;
}

.customar-service-content .about-button .theme-btns {
	border-radius: 100px;
}

.customar-service-content .about-button-area {
	margin-top: 44px;
}

@media (max-width: 991px) {
	.customar-service-content .about_content {
		padding: 80px 0 40px;
	}

	.customar-service-image {
		padding-top: 0px;
		max-width: 350px;
		margin: 0 auto;
	}
}

@media (max-width: 767px) {
	span.customar-title-center-border {
		width: 25px;
		margin: 0 15px;
	}

	.customar-support-area {
		padding: 25px 50px;
	}

	.customar-service-content .about_content {
		padding: 40px 15px;
	}
}

@media (max-width: 575px) {
	span.customar-title-center-border {
		width: 100%;
		margin: 0;
	}

	.customar-support-title {
		display: block;
	}
}

@media (min-width: 1600px) {
	.banner .slide-title {
		font-size: 54px;
	}
}

@media (max-width: 1200px) {
	:root {
		--tile-length: 65px;
	}

	.banner .slide-title {
		font-size: 38px;
		line-height: 50px;
	}
}

@media (max-width: 992px) {
	:root {
		--tile-length: 45px;
	}

	.banner {
		height: auto;
	}

	.banner .slide-title {
		font-size: 37px;
		line-height: 43px;
	}

	.hero-image-wrapper {
		margin-top: 30px;
	}

	.service-section {
		padding-top: 90px;
		background-size: 100% auto;
		margin-top: 50px;
	}

	.contact-title,
	.about-title,
	.section-title {
		margin-bottom: 13px;
		font-size: 30px;
		line-height: 43px;
	}

	.section-title-content {
		margin-bottom: 26px;
	}

	.welcome-section,
	.contactus-section {
		padding: 50px 15px 50px 15px;
	}

	.testcard--wrapper {
		padding: 0px 40px 40px;
		margin-top: 50px;
		height: auto;
		margin-bottom: 23px;
	}
}

@media (max-width: 768px) {
	.banner-image {
		max-width: 320px;
		margin: 0 auto;
	}
}

@media (max-width: 410px) {
	.page-title {
		font-size: 28px;
		line-height: 35px;
	}

	.contact-title,
	.about-title,
	.section-title {
		margin-bottom: 0px;
		font-size: 22px;
		line-height: 35px;
	}
}