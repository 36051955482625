.pricing-table {
    background-color: var(--white);
}
.pricing-table.table td, .pricing-table.table th {
	border-top: 0px;
	border-color: transparent;
    padding: 15px;
    position: relative;
}
.pricing-table.table th {
    padding: 30px 15px 10px;
}
.pricing-table.table tr td:not(:first-child) {
    width: 243px;
}
.pricing-table.table tr:nth-child(2n) td {
    background-color: var(--lightest-primary);
}
.pricing-table.table th { 
    color: var(--secondary);
}
.pricecheck-icon {
    width: 28px;
}
.pricing-table.table td:nth-child(3) {
    border-left: 3px solid var(--primary-color);
    border-right: 3px solid var(--primary-color);
}
.pricing-table.table tr:last-child td:nth-child(3)::before {
    position: absolute;
    left: -2px;
    right: -1px;
    bottom: 0;
    top: 0;
    border-left: 3px solid var(--primary-color);
    border-right: 3px solid var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
    border-radius: 0 0 20px 20px;
    content: '';
    z-index: 1;
}

.pricing-table.table tr:last-child td:nth-child(3) .btn.btn-primary{
    position: relative;
    z-index: 1;
}

.pricing-table.table thead tr th:nth-child(3)::before {
    position: absolute;
    left: -2px;
    right: -1px;
    bottom: 0;
    top: 0;
    border-left: 3px solid var(--primary-color);
    border-right: 3px solid var(--primary-color);
    border-top: 3px solid var(--primary-color);
    border-radius: 20px 20px 0 0;
    content: '';
}
.pricing_column td {
    font-size: 3rem;
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .pricing-table.table tr:last-child td:nth-child(3)::before,
    .pricing-table.table thead tr th:nth-child(3)::before {
        left: -1px;
        right: -2px;
    }
}
@media (min-width: 992px) {    
    .pricing-table.table tr:last-child td:nth-child(3){
        border: 0 none;
        padding-bottom: 30px;
    }
}
@media (max-width: 992px) {
    .pricing-table.table {
        border: 1px solid var(--light-gray);
        margin-top: 40px;
    }
    .pricing-table.table thead tr th:nth-child(3)::before,
    .pricing-table.table tr:last-child td:nth-child(3)::before,
    .pricing-table.table tr:not(.pricing-features) th:first-child ,
    .pricing-table.table tr:not(.pricing-features) td:first-child {
        display: none;
    }
    .pricing-table.table tr {
        display: flex;
        flex-wrap: wrap;
    }
    .pricing-table.table tr th:not(:first-child),
    .pricing-table.table tr td:not(:first-child) {
        width: 33.333%;
    }
    .pricing-table.table thead tr th:nth-child(3) {
        padding-top: 15px;
    }
    .pricing-table.table tr.pricing-features td.pricing-features-title {
        width: 100%;
        text-align: center;
        white-space: break-spaces;
    }
    .pricing-table.table th:nth-child(3),
    .pricing-table.table td:nth-child(3) {
        border-left: 1px solid var(--light-gray);
        border-right: 1px solid var(--light-gray);
    }
}
@media (max-width: 460px) { 
    .pricing-table.table tr .btn {
        font-size: 12px;
        padding: 6px;
    }
    .pricing_column td {
        font-size: 2rem;
    }
    .pricing-table.table tr {
        font-size: 13px;
    }
    .pricing-table.table thead tr th:nth-child(3),
    .pricing-table.table td, .pricing-table.table th {
        padding: 5px;
    }
}