/* .App {
  text-align: center;
} */
#nprogress .bar {
  height: 3px !important;
}

#nprogress .bar {
  background: #06FFF0 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #06FFF0, 0 0 5px #06FFF0 !important;
}

#nprogress .spinner-icon {
  border-top-color: #06FFF0 !important;
  border-left-color: #06FFF0 !important;
}

.rotateSpinner {
  width: 50px;
  height: 50px;
  background-color: #0CB1C4;
  transform: rotate(0deg);
  border-radius: 7px;
}

.profileButton {
  width: 70px;
  height: 10px;
}

.card {
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 0 none;
}

.card-header {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
}

.card.borderedCard {
  border-top: 5px solid var(--primary-color);
}

.rotateSpinner:hover {
  background-color: #0CB1C4;
  transform: scale(1.1);
  transition: all 0.5s ease;
}

.rotateSpinner.btn.btn-primary {
  width: 47px;
  height: 47px;
  display: block;
  margin: 0 auto;
}

.rotateSpinner.Mui-checked>span:nth-child(2) {
  position: absolute;
  right: -9px;
  top: -7px;
  background: var(--green);
  border-radius: 50%;
}

.optionsBtn {
	font-size: 10px;
  margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	position: relative;
	white-space: break-spaces;
	max-width: calc(20% - .25rem);
	line-height: 1.3;
}
.optionsBtn svg {
  fill: transparent;
}

.optionsBtn .Mui-checked svg:last-child {
  fill: var(--white);
}

.optionsBtn .Mui-checked+* {
  color: var(--black);
}

.container-section {
  padding-right: 7% !important;
  padding-left: 7% !important;
}

.qcont:first-letter {
  text-transform: capitalize
}

hr.lines {
  border-top: 1px dashed var(--gray);
  padding: 0px !important;
  margin: 0px !important;
}
