.no-data-found-popup.modal-dialog {
    width: 400px;
}
.no-data-found-popup .modal-header {
	text-align: center;
	justify-content: center;
	/* background: var(--white); */
}
/* .no-data-found-popup .modal-title::after {
	content: '!';
	display: block;
	width: 80px;
	height: 80px;
	background: transparent;
	border-radius: 50%;
	margin: 30px auto 0;
	line-height: 80px;
	color: var(--red);
	font-size: 40px;
	border: 2px solid #f43d3d;
    transform: scale(0);
    transition: all 0.3s ease 0.2s;
}
.show .modal-dialog.no-data-found-popup .modal-title::after {
    transform: scale(1);
} */
@media (max-width: 410px) { 
	.no-data-found-popup.modal-dialog {
		width: 90%;
		margin: 0 auto;
	}
}