.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Ensure it's above the modal */
}
  
.processingContent {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	text-align: center;
}