.container--contact{
    padding: 3rem;
    border-radius: 20px;	
	max-width: 600px;
	margin: 0 auto;
    background-color: var(--lightest-gray);
}
.container--contact .about-title {
	margin: 0;
}
.icon-box {
	display: flex;
	margin: 30px 0;
}
.icon {
	font-size: 18px;
	height: 50px;
	width: 50px;
	background: var(--white);
	color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	min-width: 50px;
	margin-right: 25px;
}
.icon-box-title-label {
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	color: var(--secondary);
	margin: 0px 0px 3px 0px;
}
.icon-box-title {
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	color: var(--gray);
}
.container--contact .form-control {
    color: var(--text-color);
    border: 1px solid var(--card-border);
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: none;
    outline: none;
    width: 100%;
    margin-bottom: 10px;
    max-height: 70px;
}
.container--contact textarea.form-control {
    max-height: 210px;
}
.container--contact .btn {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	padding: 18px 40px;
	display: block;
	position: relative;
	transition: all .35s;
	border: none;
	background: var(--primary-color);
	color: var(--white-color);
	transition: .4s;
    width: 100%;
	border-radius: 10px;
}