.features_wrap {
    padding: 90px 0;
}
.card--main {
	padding: 48px 40px 32px;
	background: var(--white-color);
	margin-top: 30px;
	border-radius: 15px;
	box-shadow: 0px 9px 30px 0px #EDF0F2;
	text-align: center;
	transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	border-bottom: 4px solid var(--primary-color);
    display: flex;
}
.card--img {
	width: 220px;
	margin-right: 30px;
}
.card--img img {
    width: 100%;
    height: 230px;
    object-fit: contain; 
    border-radius: 4px;
}
.card--content {
	width: calc(100% - 220px);
	text-align: left;
}
.card--title {
	font-size: 1.5em;
    text-align: left;
	color: var(--secondary);
    
}
.read--more-text {
    white-space: pre-line;
}
.figures_section {
    background: url(../../../assets/Images/Mask-group-2.jpg) no-repeat top right / cover;
    padding: 190px 0 90px;
}
.figure--main{
    text-align: center;
    padding-top: 60px;
}
.figure--title{
    font-size: 3em;
    font-weight: 700;
    color: var(--white-color);
}

.figure--content{
    color: rgba(255,255,255,0.7);
}
@media (max-width: 992px) {
    .features_wrap {
        padding: 30px 0;
    }
    .card--main {
        flex-direction: column;
    }
    .card--img {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .card--content {
        width: 100%;
    }
    .figures_section {
        padding: 50px 0 50px;
    }
    .figure--main {
        padding-top: 30px;
        min-width: 50%;
    }
}