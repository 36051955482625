form{
    /* max-width: 600px; */
    margin: auto;
}
form.invert-colors .form-control {
	min-height: 46px;
	border-radius: 6px;
	padding: 5px 10px;
	background: var(--primary);
	border: 1px solid var(--primary);
	font-size: 14px;
	color: var(--white-color);
}
form.invert-colors textarea.form-control {
    min-height: 120px;
}
form.invert-colors .form-control::placeholder {
	color: rgba(255,255,255,0.5);
}