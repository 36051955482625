.stickybar {
    position: sticky;
    top: 0;
    z-index: 6;
}
.progressbar_wrap {
    overflow: hidden;
}
.progressbar_wrap .progress-bar {
    /* width: var(--length); */
    background-color: var(--background-color);
}
.progressbar_wrap {
    display: flex;
    width: 100%;
    overflow-x: scroll;
}
.progress {
    width: 20px;
    height: 450px;
    margin-right: 10px;
    display: none;
}
.progressbar_wrap .progress-bar {
    width: 20px;
    height: var(--length);
    margin-bottom: auto;
}
.term-list {
    display: flex;
    border: 0 none;
    margin-top: 0px;
    flex-direction: row;
}
.term-list .list-group-item {
    /* width: var(--length); */
    width: 100%;
    height: var(--length);
    border: 0 none;
    padding: 15px 0;
    text-align: left;
    position: relative;
}
.term-list .list-group-item::before {
    position: absolute;
    content: '';
    /* border-left: 1px dashed var(--light-gray); */
    /* width: 0px;
    height: 1000px; */
    width: 1000px;
    height: 0px;
    border-bottom: 1px dashed var(--gray);
    bottom: 100%;
    right: 0;
    
    text-align: left;
}
/* .term-list .list-group-item::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 10px;
    background-color: var(--light-gray);
    top: 0;
    right: 0;
} */
.term-list {
	flex-direction: column;
	height: 450px;
	width: 100%;
}

.highcharts-point {
    width: 20px !important;
    height: 20px !important;
}

.highcharts-container {
    padding-bottom: 20px !important;
}
.test_user_card {
	font-size: 0.8em;
	line-height: 1.3;
}
.test_user_card .card {
	height: 100%;
    background-color: var(--lightest-primary);
}

.scatter_user_icon.hide {
    display: none !important;
    opacity: 0 !important;
}
.rsw-btn[title="HTML mode"] {
	display: none;
}
.foursideGraph .container {
    max-width: 100%;
    padding: 0;
    margin: 0;
}
.foursideGraph .highcharts-container{
    max-width: 600px !important;
    margin: 0 auto !important;
}

.foursideGraph .highcharts-container svg {
    width: 100% !important;
}


@page {
    margin: 0.5cm;  
    size: auto;
}
@media print {
    * {
        color-adjust: exact!important;  
        -webkit-print-color-adjust: exact!important; 
         print-color-adjust: exact!important;
         box-sizing: border-box;
      }

    h3 {
        font-size: 13px;
    }
    h5,
    p {
        font-size: 11px;
    }
    .container {
        width: 100% !important;
        max-width: 100%  !important;
        margin: 0 !important;
        display: flex !important;
        flex-wrap: wrap !important;
    }
      
    .dashboar-container .tab-content .tab-pane > .container, .dashboar-container .dashboardTopbar + .container {
        padding: 0 !important;
    }

    .tab-content * {
        width: 100%;
        margin: 0;
    }
    
    .showHideDiv p {
        line-height: 1.2;
    }
    .test_user_card {
        font-size: 0.9em;
        margin-bottom: 5px;
    }    
    .test_user_card .badge {
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px;
        width: auto;
    }  
    .alert {
        margin-top: 5px;
    }
    .highcharts-container,
    .highcharts-container * {
        max-width: 100%;
        margin: 0 auto;
    }

    .chart-wrapper .highcharts-container,
    .chart-wrapper .highcharts-container * {
        max-height: 200px !important;
    }

    .foursideGraph .highcharts-container svg {
        width: 600px !important;
        margin-left: auto !important;
        margin-right: 60px !important;
    }
    .foursideGraph .highcharts-container rect[stroke="black"]{
        width: 150px;
        transform: translateX(-5px);
    }
    .foursideGraph .highcharts-container .highcharts-xaxis .highcharts-axis-line {
        width: 100% !important;
        transform: translateX(5px);
    }
    .highcharts-point {
        width: 12px !important;
        height: 12px !important;
        transform: translate(-5px, -5px);
    }

    .user_image{
        width: 12px !important;
        height: 12px !important;
    }

    .scatter_text{
        font-size: 14px !important;
    }

    .scatter_circle{
        width: 35px !important;
        height: 35px !important;
    }

    .card{
        page-break-inside: avoid; 
    }
    /* .print-half.row {
        display: block !important;
        height: auto;
     } */
    /*.print-four.row {
        display: block !important;
        height: auto;
    } */
    /* .print-half .foursideGraph {
        max-width: 300px;
    } */

    .show_result_for {
        margin-left: 12px;
    }
    .print-four.row .test_user_card {
        display: inline-block !important;
        width: 25% !important;
        margin-left: 8px;
    }
    .print-half.row > div {
        display: inline-block !important;
        width: 50% !important;
        page-break-inside: avoid !important; 
        -webkit-column-break-inside: avoid;
        break-inside: avoid;
        height: auto;
    }
    .ios-print .print-half.row {
        display: block !important;
        height: auto;
    }
    .print-half.row > div .card-body,
    .print-half.row > div .alert {
        padding: 5px;
        font-size: 10px;
    }
}

@media print  and (orientation: portrait) {
    .progress {
        width: 20px;
        max-width: 30px;
        margin-right: 10px;
    }
    
}
